<template>
    <div class="border-2 border-[#E8E8E8] rounded-3xl p-6 md:p-10 bg-white flex flex-col">
        <HomeActionItemsList :action-items="actionItems.slice(0, MAX_ACTION_ITEMS)" :action-items-id="actionItemsData.id" />
        <template v-if="actionItems.length > MAX_ACTION_ITEMS">
            <button v-if="!allActionItemsCompleted" class="text-center w-full font-semibold text-[#555BA2] mt-auto py-3" @click="openModal">
                See {{ actionItems.length - MAX_ACTION_ITEMS }} more
            </button>
            <dialog
                ref="actionItemsDialog"
                class="border-2 border-[#E8E8E8] max-w-full md:max-w-lg w-full md:w-auto m-0 mt-auto md:m-auto focus-visible:outline-0 bg-white px-10 py-6 rounded-b-none md:rounded-b-xl rounded-xl text-valence-grey-800 relative flex flex-col"
            >
                <HomeActionItemsList :action-items="actionItems" :action-items-id="actionItemsData.id" />
                <button class="rounded-full text-white bg-[#555BA2] hover:bg-[#555BA2]/80 font-bold text-sm py-3 px-16 leading-6 self-center" @click="dismissModal">Dismiss</button>
            </dialog>
        </template>
        <span v-else-if="allActionItemsCompleted" class="self-center">
            <BaseButton theme="startChat" @click="openUrl(defaultChatUrl)">
                <CoachingModeMarble class="w-6" />
                Start Chat
            </BaseButton>
        </span>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { computed, useTemplateRef } from "vue";

import HomeActionItemsList from "./HomeActionItemsList.vue";

const MAX_ACTION_ITEMS = 3;

const actionItemsDialog = useTemplateRef("actionItemsDialog");

const { actionItemsData, defaultChatUrl } = defineProps({
    actionItemsData: {
        type: Object,
        required: true,
    },
    defaultChatUrl: {
        type: String,
        required: true,
    },
});

const allActionItemsCompleted = computed(() => actionItemsData.items.every((item) => item.checked));

const actionItems = computed(() => actionItemsData.items);

const openModal = () => {
    actionItemsDialog.value.showModal();
};

const dismissModal = () => {
    actionItemsDialog.value.close();
};
</script>

<style scoped>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}

dialog:not([open]) {
    display: none;
}
</style>
