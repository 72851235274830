<template>
    <div class="bg-white rounded-3xl border-2 p-6 md:p-10 flex flex-col gap-6 text-center items-center relative grow" :class="{ bottomBlur: isEmptyState }">
        <h3 class="font-semibold md:text-2xl mb-1 whitespace-nowrap tracking-tight">{{ focusAreaTitle }}</h3>
        <div class="flex justify-center">
            <BubbleChart :is-empty-state="isEmptyState" :data="userIntentCategories" />
        </div>
        <button
            v-if="isEmptyState"
            class="rounded-full border-2 border-[#E8E8E8] bg-white font-semibold py-3 px-6 max-w-fit inline-flex gap-2 items-center text-center absolute bottom-6 z-20"
            @click="openUrl(startChatUrl)">
            <i class="bi bi-shield-lock text-2xl"></i>Chat with Nadia
        </button>
    </div>
</template>

<script setup>
import BubbleChart from "~vue/components/BubbleChart.vue";
import { openUrl } from "~vue/utils";
import { computed } from "vue";

const { userIntentCategories, startChatUrl } = defineProps({
    userIntentCategories: {
        type: Object,
        default: () => ({}),
    },
    startChatUrl: {
        type: String,
        default: "",
    },
});

const isEmptyState = computed(() => Object.keys(userIntentCategories).length < 2);
const focusAreaTitle = computed(() => (isEmptyState.value ? "Unlock your personal growth map" : "What we discuss most"));
</script>

<style scoped>
.bottomBlur::after {
    content: "";
    @apply rounded-[1.35rem] absolute z-10 inset-0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.14) 100%);
}
</style>
