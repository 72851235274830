<template>
    <HomeSectionContainer class="bg-white border-2 border-[#B8C9FF] mx-6 md:mx-0 p-6 rounded-[20px]">
        <template #title>
            <div class="flex gap-6 items-center justify-between md:justify-start mb-3">
                <h3 class="md:text-2xl font-semibold tracking-tight">{{ title }}</h3>
                <span class="border-2 border-[#E8E8E8] text-[#525252] rounded-lg py-1 px-2 md:py-2 tracking-tight font-semibold">3 min</span>
            </div>
        </template>
        <div class="flex flex-col md:flex-row gap-6 items-center justify-between">
            <span class="w-16 h-16 inline-flex items-center" :class="[hasThreeConversations ? 'md:w-32 md:h-32' : 'md:w-20 md:h-20']">
                <img :src="hasThreeConversations ? rocketSrc : lockSrc" alt="Rocket" class="w-full" />
            </span>
            <div>
                <h3 class="font-semibold tracking-tight md:text-2xl mb-2">
                    {{ header }}
                </h3>
                <p class="text-[#8C8C8C] font-medium text-sm md:text-base">
                    {{ description }}
                </p>
            </div>
            <BaseButton v-if="hasThreeConversations" theme="primary" class="whitespace-nowrap" @click="handleWrappedClick">
                Let's dive in <i class="bi bi-arrow-right ml-2"></i
            ></BaseButton>
            <BaseButton v-else theme="startChat" class="whitespace-nowrap" @click="openUrl(startChatUrl)">
                <div class="w-6 h-6">
                    <CoachingModeMarble />
                </div>
                Start Chat
            </BaseButton>
        </div>
    </HomeSectionContainer>
</template>

<script setup>
import lockSrc from "~img/wrapped/lock.png";
import rocketSrc from "~img/wrapped/rocket.png";
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed } from "vue";

import HomeSectionContainer from "./HomeSectionContainer.vue";

const { wrappedUrl, userConversationCount } = defineProps({
    wrappedUrl: { type: String, default: "/wrapped/" },
    startChatUrl: { type: String, default: "/coach/" },
    userConversationCount: { type: Number, required: true },
});

const hasThreeConversations = computed(() => userConversationCount >= 3);
const title = computed(() => (hasThreeConversations.value ? "Let's take on 2025 together" : "Unlock your 2025 plan"));
const header = computed(() => (hasThreeConversations.value ? "I've been keeping notes, and you've had quite the year." : "You've started something great"));
const description = computed(() =>
    hasThreeConversations.value ? "Let's look back - and plan for what's next." : "Have 3 conversations with Nadia to unlock your personalized roadmap for 2025.",
);

const handleWrappedClick = () => {
    logUserInteraction("homepage_wrapped_card_clicked;");
    openUrl(wrappedUrl);
};
</script>
