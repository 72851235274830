<template>
    <div class="flex flex-col gap-3 items-center justify-center text-center px-4">
        <div class="w-16 h-16">
            <CoachingModeMarble />
        </div>
        <p class="font-semibold tracking-tight text-[#8C8C8C]">I'm curious to hear more about:</p>
        <div class="max-w-[800px]">
            <h1 class="text-2xl md:text-4xl font-semibold tracking-[-1.6px] max-w-[500px] inline">
                {{ questionText }}
            </h1>
            <button
                :disabled="isSaving || isTranscribing"
                class="relative -top-1 ml-2 rounded-full bg-white border-2 border-[#E8E8E8] w-8 h-8 inline-flex justify-center items-center"
                @click="$emit('new-question')">
                <i class="bi bi-arrow-clockwise leading-none text-xl"></i>
            </button>
        </div>
    </div>
    <div class="pt-14 flex justify-center">
        <div class="max-w-[800px] w-full">
            <BaseNotification v-if="error" theme="info" :text="error" @dismiss="error = null" />
            <ChatMessageControls
                :disabled="isSaving || isTranscribing"
                is-paste-disabled
                :is-transcribing="isTranscribing"
                @text-send="handleTextSend"
                @text-keydown="handleTextKeydown"
                @recording-complete="handleRecordingComplete" />
        </div>
    </div>
    <div class="overflow-y-hidden p-4">
        <div class="flex gap-2 flex-nowrap text-center">
            <button
                v-for="{ id, start_url, external_name } in badges"
                :key="id"
                type="button"
                class="rounded-xl bg-white border-2 border-[#E8E8E8] py-2 px-3 whitespace-nowrap font-semibold tracking-[-0.64px] flex gap-2"
                @click="openUrl(start_url)">
                {{ external_name }}<i class="bi bi-arrow-up-right text-xl leading-none icon-bold" />
            </button>
        </div>
    </div>
</template>

<script setup>
import ChatMessageControls from "~vue/ChatWidgets/ChatMessageControls.vue";
import BaseNotification from "~vue/components/BaseNotification.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { logError } from "~vue/utils/logUtils";
import { computed, inject, ref } from "vue";

import { encodeAudio, transcribeRecording } from "../../transcription.js";

const { $sendEvent } = inject("globalProperties");

const emit = defineEmits(["new-question", "send-message", "transcription-error"]);

const { isSaving, questionText, suggestedTopics, organizationPrompts } = defineProps({
    isSaving: { type: Boolean, required: true },
    questionText: { type: String, required: true },
    suggestedTopics: { type: Array, default: () => [] },
    organizationPrompts: { type: Array, default: () => [] },
});

const isTranscribing = ref(false);
const error = ref(null);

const badges = computed(() => {
    const MAX_BADGES = 3;
    const MAX_SUGGESTED_TOPICS = 2;
    const topics = suggestedTopics.slice(0, MAX_SUGGESTED_TOPICS).map(({ prompt_id, start_url, external_name }) => ({
        id: prompt_id,
        start_url,
        external_name,
    }));
    const orgPrompts = organizationPrompts.map(({ id, start_url, external_name }) => ({
        id,
        start_url,
        external_name,
    }));
    return [...topics, ...orgPrompts].slice(0, MAX_BADGES);
});

const handleTextSend = ({ content }) => {
    error.value = null;
    emit("send-message", { content });
};

const handleTextKeydown = () => {
    error.value = null;
};

const handleRecordingComplete = async ({ blob }) => {
    try {
        error.value = null;
        const encodedAudio = await encodeAudio(blob);
        const transcript = await transcribeRecording({
            encodedAudio,
            sendEvent: $sendEvent,
            onStart: () => {
                isTranscribing.value = true;
            },
            onError: () => {
                error.value = "Something went wrong. Please try again.";
                logError("Transcription request failed");
            },
        });
        isTranscribing.value = false;
        emit("send-message", { content: transcript });
    } catch (e) {
        error.value = "Something went wrong. Please try again.";
        logError(`Transcription request failed: ${e}`);
        isTranscribing.value = false;
        emit("transcription-error");
    }
};
</script>

<style scoped></style>
