<template>
    <HomeSectionCardsContainer v-if="organizationName && organizationPrompts.length > 0" :title="sectionTitle" subtitle="Trending" :card-props="organizationPrompts">
        <template #cards="{ cardProps }">
            <HomeTrendingOrgCard
                :key="cardProps.external_name"
                :content="cardProps.content"
                :title="cardProps.external_name"
                :url="cardProps.start_url"
                :icon-url="organizationIconImgSrc"
                class="min-w-64" />
        </template>
        <template v-if="organizationImgSrc" #additional-content>
            <div class="flex items-center">
                <img :src="organizationImgSrc" :alt="`${organizationName} image`" class="max-w-64" />
            </div>
        </template>
    </HomeSectionCardsContainer>
</template>

<script setup>
import HomeTrendingOrgCard from "~vue/ChatHome/HomeTrendingOrgCard.vue";
import { computed } from "vue";

import HomeSectionCardsContainer from "./HomeSectionCardsContainer.vue";

const { organizationName, organizationPrompts, organizationIconImgSrc, organizationImgSrc, isDefaultOrganization } = defineProps({
    organizationName: {
        type: String,
        default: undefined,
    },
    organizationPrompts: {
        type: Array,
        default: () => [],
    },
    organizationImgSrc: {
        type: String,
        default: undefined,
    },
    organizationIconImgSrc: {
        type: String,
        default: undefined,
    },
    isDefaultOrganization: {
        type: Boolean,
        default: false,
    },
});

const sectionTitle = computed(() => {
    const orgName = !organizationName || isDefaultOrganization ? "your organization" : organizationName;
    return `Life at ${orgName}`;
});
</script>
