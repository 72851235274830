<template>
    <Bubbles direction="top" />
    <div class="antialiased overflow-scroll py-6 pb-[var(--mobile-nav-total-height)] md:pb-6">
        <ScheduleFollowupDialog ref="scheduleFollowupDialog" :coach-followup-events="coach_followup_events" />
        <div class="mx-auto flex flex-col gap-6 md:gap-10 max-w-[1084px] px-0 md:px-16">
            <div class="flex flex-col gap-2">
                <div class="px-4 md:px-0 flex items-center justify-between">
                    <span class="max-w-[94px]">
                        <OrganizationLogo />
                    </span>
                    <span>
                        <PrivacyDialog />
                    </span>
                </div>
                <GlobalNoticeBanner />
                <HomeDisclaimerMessage class="mx-6 md:mx-0" :message="disclaimer_message" />
                <HomeHeader
                    v-if="!hasNoUserConversations"
                    :start-chat-url="default_chat_redirect_url"
                    :name="user_details.first_name"
                    :coach-followup-events="coach_followup_events"
                    @schedule="() => scheduleFollowupDialog?.open({ showCalendar: true })" />
            </div>
            <div v-if="hasNoUserConversations" class="max-w-[926px] mb-10">
                <DailyReflection
                    :is-saving="isSaving"
                    :question-text="daily_question.text"
                    :suggested-topics="suggested_topics"
                    :organization-prompts="organization_prompts"
                    @new-question="handleNewQuestion"
                    @send-message="handleSendMessage"
                    @transcription-error="isSaving = false" />
            </div>
            <HomeWrapped v-if="can_view_wrapped" :user-conversation-count="user_conversation_count" :start-chat-url="default_chat_redirect_url" :wrapped-url="wrapped_url" />
            <HomePersonalization v-if="!can_view_wrapped && !has_answered_onboarding_questions && user_conversation_count < 4" :onboarding-question-url="onboarding_question_url" />
            <div v-if="shouldShowDailyQuestion" class="grid grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4 md:gap-10 w-full px-6 md:px-0">
                <DailyQuestionCard class="w-full" :daily-question="daily_question" :is-demo="props.user_details.is_demo" />
                <HomeActionItemsSection v-if="action_items.items?.length > 0" :action-items-data="action_items" :default-chat-url="default_chat_redirect_url" />
            </div>
            <HomeNewUserChecklist
                :new-user-checklist-items="new_user_checklist"
                :user-created-at="user_details.user_created_at"
                :organization-prompt-title="organization_prompts[0]?.external_name"
                :organization-prompt-url="organization_prompts[0]?.start_url"
                :organization-icon-src="$organizationDetails.value?.logo_url"
                :default-chat-url="default_chat_redirect_url"
                :insight-chat-url="user_insights[0]?.chat_url"
                @schedule="(eventProps) => scheduleFollowupDialog?.open({ eventData: eventProps?.eventData, showCalendar: true })" />
            <HomeSuggestedTopicsSection :topics="suggested_topics" />
            <div class="grid grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4 md:gap-10 w-full px-6 md:px-0">
                <HomeSkillBuildingPlanSection v-if="skills && skills.length > 0" :skills="skills" />
                <HomeConversationTopics :user-intent-categories="user_intent_categories" :start-chat-url="default_chat_redirect_url" />
            </div>
            <div :class="{ 'order-last': user_insights.length === 0 }">
                <HomeCoachingInsightsSection :user-insights="user_insights" :default-chat-url="default_chat_redirect_url" />
            </div>
            <TrendingAtOrganizationSection
                :organization-name="$organizationDetails.value.name"
                :organization-prompts="organization_prompts"
                :organization-icon-img-src="$organizationDetails.value.logo_url"
                :is-default-organization="$organizationDetails.value.is_default" />
            <!-- internal only feature -->
            <div v-if="review_enabled" class="grid grid-cols-12 gap-4 mx-auto w-full order-last">
                <div class="col-span-12 md:col-span-4">
                    <HomeReview :review="review" />
                </div>
            </div>
        </div>
    </div>
    <CookieBanner />
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import { router } from "@inertiajs/vue3";
import { useFetch } from "~vue/api";
import DailyQuestionCard from "~vue/ChatHome/DailyQuestionCard.vue";
import DailyReflection from "~vue/ChatHome/DailyReflection.vue";
import HomeActionItemsSection from "~vue/ChatHome/HomeActionItemsSection.vue";
import HomeCoachingInsightsSection from "~vue/ChatHome/HomeCoachingInsightsSection.vue";
import HomeDisclaimerMessage from "~vue/ChatHome/HomeDisclaimerMessage.vue";
import HomeHeader from "~vue/ChatHome/HomeHeader.vue";
import HomeNewUserChecklist from "~vue/ChatHome/HomeNewUserChecklist.vue";
import HomePersonalization from "~vue/ChatHome/HomePersonalization.vue";
import HomeReview from "~vue/ChatHome/HomeReview.vue";
import HomeSkillBuildingPlanSection from "~vue/ChatHome/HomeSkillBuildingPlanSection.vue";
import HomeSuggestedTopicsSection from "~vue/ChatHome/HomeSuggestedTopicsSection.vue";
import HomeWrapped from "~vue/ChatHome/HomeWrapped.vue";
import TrendingAtOrganizationSection from "~vue/ChatHome/TrendingAtOrganizationSection.vue";
import Bubbles from "~vue/components/Bubbles.vue";
import CookieBanner from "~vue/components/CookieBanner.vue";
import OrganizationLogo from "~vue/components/OrganizationLogo.vue";
import ScheduleFollowupDialog from "~vue/components/ScheduleFollowupDialog.vue";
import GlobalNoticeBanner from "~vue/GlobalNoticeBanner.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import { useUserStore } from "~vue/stores/userStore.js";
import { openUrl } from "~vue/utils";
import { computed, defineProps, inject, onMounted, onUnmounted, ref, watchEffect } from "vue";

import HomeConversationTopics from "../ChatHome/HomeConversationTopics.vue";

const globalProperties = inject("globalProperties");
const scheduleFollowupDialog = ref(null);

const props = defineProps({
    review_enabled: {
        type: Boolean,
        default: () => false,
    },
    review: {
        type: Object,
        default: () => null,
    },
    default_chat_redirect_url: {
        type: String,
        default: () => "",
    },
    disclaimer_message: {
        type: String,
        default: () => null,
    },
    user_details: {
        type: Object,
        default: () => {},
    },
    user_insights: {
        // holds an array of insight objects, containing id, text, chat_url
        type: Array,
        default: () => [],
    },
    suggested_topics: {
        type: Array,
        default: () => [],
    },
    skills: {
        type: Array,
        default: () => [],
    },
    action_items: {
        type: Object,
        default: () => {},
    },
    event_context: {
        type: Object,
        default: () => {},
    },
    daily_question: {
        type: Object,
        default: () => {},
    },
    user_intent_categories: {
        type: Object,
        default: () => {},
    },
    coach_followup_events: {
        type: Array,
        default: () => [],
    },
    user_profile_data_count: {
        type: Number,
        default: 0,
    },
    user_profile_questions_count: {
        type: Number,
        default: 0,
    },
    user_conversation_count: {
        type: Number,
        default: 0,
    },
    user_team_members: {
        type: Array,
        default: () => [],
    },
    new_user_checklist: {
        type: Object,
        default: () => ({
            chat: false,
            mic: false,
            schedule: false,
            "role-play": false,
            upload: false,
            organization: false,
            insight: false,
        }),
    },
    organization_prompts: {
        type: Array,
        default: () => [],
    },
    has_answered_onboarding_questions: {
        type: Boolean,
        default: true,
    },
    onboarding_question_url: {
        type: String,
        default: undefined,
    },
    can_view_wrapped: {
        type: Boolean,
        default: false,
    },
    wrapped_url: { type: String, default: "/wrapped/" },
});

const shouldShowDailyQuestion = computed(() => {
    // new user onboarding drops you into a conversation which automatically creates a conversation, so we check for > 1
    return props.user_conversation_count > 1 || props.user_details.is_demo;
});

const hasNoUserConversations = computed(() => props.user_conversation_count === 0);

const isSaving = ref(false);

const handleNewQuestion = () => {
    router.reload({ only: ["daily_question"] });
};

const handleSendMessage = async ({ content }) => {
    const question = props.daily_question.id;
    const answer = content;

    const { isFetching, error, data } = await useFetch("/api/chat/coach/daily-question")
        .post({
            question,
            answer,
        })
        .json();
    isSaving.value = isFetching.value;
    if (data.value && !error.value) {
        const dailyQuestionAnswerId = data.value.answer.id;
        openUrl(`${props.default_chat_redirect_url}?daily_question_answer_id=${dailyQuestionAnswerId}`);
    }
};

watchEffect(
    () => {
        useUserStore.setTeamMembers(props.user_team_members);
    },
    { immediate: true },
);

onMounted(() => {
    // We need this so we can send the request-transcription event for the daily question card.
    globalProperties.$setEventContext(props.event_context);
    const params = new URLSearchParams(window.location.search);
    if (params.get("schedule_followup")) {
        scheduleFollowupDialog.value.open();
    }
});

onUnmounted(() => {
    globalProperties.$setEventContext({});
});
</script>
