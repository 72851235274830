<!-- note, this is somewhat similar to ActionItemsList, need more granular design system components for this -->
<template>
    <div class="flex justify-between items-center">
        <h3 class="md:text-2xl font-semibold tracking-tight">{{ sectionTitle }}</h3>
        <span class="h-10 w-10">
            <ProgressCircle :progress="progress.percent" :content="progress.content" />
        </span>
    </div>
    <div class="flex flex-col gap-6 py-4 md:py-6 justify-evenly">
        <div v-for="(item, index) in actionItems" :key="index" class="flex justify-between items-center gap-4 py-2">
            <BaseCheckbox :checked="item.checked" @toggle-check="toggleActionItem(item)">
                {{ item.content }}
            </BaseCheckbox>
            <button v-if="!!item.chat_url" class="text-[#555BA2] font-semibold flex gap-3 items-center" @click="openChat(item)">Chat<i class="bi bi-arrow-right"></i></button>
        </div>
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import BaseCheckbox from "~vue/components/form/BaseCheckbox.vue";
import ProgressCircle from "~vue/components/ProgressCircle.vue";
import { openUrl } from "~vue/utils";
import { logError } from "~vue/utils/logUtils";
import { computed, nextTick } from "vue";

const { actionItemsId, actionItems } = defineProps({
    actionItemsId: { type: String, required: true },
    actionItems: { type: Array, required: true },
});

const sectionTitle = computed(() => (actionItems.every((item) => item.checked) ? "What else is on your mind?" : "Your next steps"));

async function toggleActionItem(actionItem) {
    const updatedAction = { ...actionItem, checked: !actionItem.checked };
    try {
        const response = await fetch("/accounts/action_items", {
            method: "POST",
            body: JSON.stringify({
                id: actionItemsId,
                action_item: updatedAction,
            }),
            headers: {
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });
        const body = await response.json();
        if (!response.ok) {
            logError("Failed to complete action");
            return;
        }

        if (!body.success) {
            logError(body.error);
            return;
        }
    } catch (error) {
        logError(error);
    } finally {
        // reload the action items to refresh the state
        router.reload({ only: ["action_items"] });
    }
}

const openChat = async (item) => {
    if (!item.checked) {
        await toggleActionItem(item);
    }
    nextTick(() => {
        openUrl(item.chat_url);
    });
};

const progress = computed(() => {
    const completed = actionItems.filter((item) => item.checked).length;

    return {
        percent: (completed / actionItems.length) * 100,
        content: `${completed}/${actionItems.length}`,
    };
});
</script>

<style scoped></style>
