<template>
    <div class="border-2 border-[#E8E8E8] rounded-3xl p-6 md:p-10 bg-white flex flex-col relative">
        <HomeSkillBuildingPlan :section-title="sectionTitle" :skill-plan="skills[0]" :show-view-all="skills.length > 1" @open-modal="openModal" />
        <template v-if="skills.length > 1">
            <dialog
                ref="skillBuildingPlanDialog"
                class="border-2 border-[#E8E8E8] max-w-full md:max-w-lg w-full md:w-auto m-0 mt-auto md:m-auto focus-visible:outline-0 bg-white p-10 rounded-b-none md:rounded-b-xl rounded-xl text-valence-grey-800 relative flex flex-col">
                <div v-for="(skill, i) of skills" :key="skill.id" class="pb-8">
                    <HomeSkillBuildingPlan :skill-plan="skill" :section-title="getSectionTitle(i)" />
                    <HorizontalDivider v-if="skills.length - 1 !== i" />
                </div>
                <button class="rounded-full text-white bg-[#555BA2] hover:bg-[#555BA2]/80 font-bold text-sm py-3 px-16 leading-6 self-center" @click="dismissModal">Dismiss</button>
            </dialog>
        </template>
    </div>
</template>

<script setup>
import HorizontalDivider from "~vue/components/HorizontalDivider.vue";
import { computed, useTemplateRef } from "vue";

import HomeSkillBuildingPlan from "./HomeSkillBuildingPlan.vue";

const skillBuildingPlanDialog = useTemplateRef("skillBuildingPlanDialog");

const { skills } = defineProps({
    skills: {
        type: Object,
        required: true,
    },
});

const sectionTitle = computed(() => {
    if (skills[0]?.status === "completed") {
        return "You've mastered this! What's next?";
    }
    return "Current Focus";
});

const getSectionTitle = (index) => {
    if (skills[index]?.status === "completed") {
        return "You've mastered this! What's next?";
    }
    return `Focus #${index + 1}`;
};

const openModal = () => {
    skillBuildingPlanDialog.value.showModal();
};

const dismissModal = () => {
    skillBuildingPlanDialog.value.close();
};
</script>

<style scoped>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}

dialog:not([open]) {
    display: none;
}
</style>
