<template>
    <div class="flex flex-col md:flex-row w-full flex-wrap justify-center md:justify-between text-center md:text-left items-center md:items-end gap-6">
        <div class="flex flex-col gap-2 max-w-72 items-center md:items-start">
            <span class="font-semibold text-[#8C8C8C]">{{ dateToday }}</span>
            <div class="text-2xl sm:text-4xl font-semibold tracking-tighter">
                <template v-if="noFollowup">{{ nameGreeting }}schedule our next chat?</template>
                <template v-else-if="nextFollowup">
                    <span class="text-[#8C8C8C] whitespace-nowrap">{{ nameGreeting }}our next check-in is</span>
                    {{ nextFollowup }}
                </template>
                <!-- I left this case in for when we want to show content when user has had a follow up and doesn't have another scheduled -->
                <!-- <template v-else-if="!noFollowup && !nextFollowup">{{ nameGreeting }}</template> -->
                <template v-else>{{ nameGreeting }}how's your day going?</template>
            </div>
        </div>
        <div class="flex gap-2">
            <button
                v-if="!!startChatUrl"
                class="rounded-full bg-white transition-colors font-semibold text-[#555BA2] px-6 py-3 flex gap-2 items-center border-2 border-valence-grey-200 whitespace-nowrap"
                @click="emit('schedule')">
                <i class="bi bi-calendar"></i>
                <template v-if="noFollowup">Schedule next chat</template>
                <template v-else-if="nextFollowup">View Schedule</template>
                <template v-else> Schedule</template>
            </button>
            <BaseButton theme="startChat" @click="openUrl(startChatUrl)">
                <CoachingModeMarble class="w-6" />
                Start Chat
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import BaseButton from "~vue/components/BaseButton.vue";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import { openUrl } from "~vue/utils";
import { DateTime } from "luxon";
import { computed } from "vue";

import { convertToDateTime } from "../../dateUtils.js";

const dateToday = DateTime.now().toFormat("LLL dd, yyyy");
const emit = defineEmits(["schedule"]);

const { name, coachFollowupEvents } = defineProps({
    startChatUrl: {
        type: String,
        default: "",
    },
    name: {
        type: String,
        default: "",
    },
    coachFollowupEvents: {
        type: Array,
        default: () => [],
    },
});

const nameGreeting = computed(() => (name ? `${name}, ` : ""));

const noFollowup = computed(() => coachFollowupEvents.length === 0);

const nextFollowup = computed(() => {
    const followups = coachFollowupEvents.filter(({ event_at_confirmed }) => convertToDateTime(event_at_confirmed) > DateTime.now());
    return followups.length > 0 ? convertToDateTime(followups[0].event_at_confirmed).toFormat("LLL dd @ t") : undefined;
});
</script>
