<template>
    <div class="flex flex-col gap-4 md:gap-6 grow justify-between">
        <h3 class="md:text-2xl font-semibold tracking-tight">{{ sectionTitle }}</h3>
        <div class="flex items-center">
            <template v-for="(_, i) of skillBoxes" :key="i">
                <div
                    class="rounded-xl flex flex-col items-center justify-center border-2 border-[#E8E8E8] bg-[#FAFAFA] w-20 h-20 font-semibold"
                    :class="{ '!border-[#B7EB8F] !bg-[#F6FFED] !text-[#2AB756] !cursor-default': isWeekDone(i), '!bg-white cursor-pointer': isCurrentWeek(i) }"
                    @click="() => onSkillWeekBoxClick(i)">
                    <template v-if="isWeekDone(i)">
                        <i class="bi bi-check text-2xl"></i>
                        <span class="whitespace-nowrap">Week {{ i + 1 }}</span>
                    </template>
                    <template v-else-if="isCurrentWeek(i)">
                        <span class="whitespace-nowrap">Week {{ i + 1 }}</span>
                    </template>
                    <template v-else>
                        <i class="bi bi-lock text-2xl"></i>
                    </template>
                </div>
                <div v-if="i !== skillBoxes.length - 1" class="w-4 border border-[#E8E8E8]"></div>
            </template>
        </div>
        <div class="flex justify-between gap-4 cursor-pointer" @click="openUrl(skillPlan.lesson_url)">
            <h3 class="md:text-2xl font-semibold tracking-tighter">
                {{ promptTitle }}
            </h3>
            <span class="rounded-full flex items-center justify-center p-4 w-5 h-5 bg-slate-300 self-end">
                <i class="bi bi-arrow-right text-lg text-[#555BA2] iconBold"></i>
            </span>
        </div>
        <div class="flex justify-between">
            <label v-if="!skillPlan.completed_at" class="flex items-center gap-3 font-semibold cursor-pointer" :class="{ 'text-[#555BA2]': isMarkedDone }">
                <input type="checkbox" class="hidden" @click="markAsDone(currentWeekIndex)" />
                <i v-if="isMarkedDone" class="text-xl leading-4 bi bi-check-circle-fill"></i>
                <i v-else class="text-xl leading-4 bi bi-circle"></i>
                Mark as done
            </label>
            <button v-if="showViewAll" class="text-[#555BA2] font-semibold flex gap-3 items-center ml-auto" @click="$emit('openModal')">
                View All <i class="bi bi-arrow-right"></i>
            </button>
        </div>
    </div>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import { openUrl } from "~vue/utils";
import { logError } from "~vue/utils/logUtils";
import { computed, ref } from "vue";

const { sectionTitle, skillPlan, showViewAll } = defineProps({
    sectionTitle: { type: String, required: true },
    skillPlan: { type: Object, required: true },
    showViewAll: { type: Boolean, default: false },
});

defineEmits([["openModal"]]);
const promptTitle = computed(() => skillPlan.title);

const skillBoxes = computed(() => new Array(+skillPlan.total_number_of_weeks));

// current week number is not returned when complete
const isWeekDone = (index) => !skillPlan.current_week_number || index + 1 < +skillPlan.current_week_number;
const isCurrentWeek = (index) => index + 1 === +skillPlan.current_week_number;

const currentWeekIndex = computed(() => +skillPlan.current_week_number - 1);

const isMarkedDone = ref(!!skillPlan.completed_at);

const markAsDone = async () => {
    if (skillPlan.completed_at) {
        return;
    }
    isMarkedDone.value = true;
    try {
        const response = await fetch(`api/chat/skill-building-lesson/${skillPlan.id}`, {
            method: "POST",
            body: JSON.stringify({
                status: "completed",
            }),
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        });
        if (!response.ok) {
            logError("Failed to complete action");
        }
        router.reload({ only: ["skills"] });
    } catch (error) {
        logError(error);
    }
};

const onSkillWeekBoxClick = (index) => {
    if (isCurrentWeek(index)) {
        markAsDone();
    }
};
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
