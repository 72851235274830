<template>
    <HomeCard @click="handleClick">
        <div class="flex flex-col justify-between gap-6" :class="{ grow: !content }">
            <div class="flex flex-col justify-between gap-2">
                <img v-if="iconUrl" :src="iconUrl" class="max-w-12 max-h-12" />
                <i v-else class="bi bi-people text-2xl"></i>
                <span class="md:text-2xl font-semibold tracking-tight">{{ title }}</span>
            </div>
            <span v-if="!!content" class="text-xs text-valence-grey-600">{{ content }}</span>
        </div>
    </HomeCard>
</template>

<script setup>
import { openUrl } from "~vue/utils";
import { logUserInteraction } from "~vue/utils/logUtils";
import { defineProps } from "vue";

import HomeCard from "./HomeCard.vue";

const props = defineProps({
    isTrending: Boolean,
    isSaved: Boolean,
    title: { type: String, default: "" },
    url: { type: String, required: true },
    content: { type: String, default: "" },
    iconUrl: { type: String, default: "" },
});

function handleClick() {
    logUserInteraction("trending_org_prompt_clicked", { title: props.title });
    if (props.url) {
        openUrl(props.url);
    }
}
</script>

<style scoped>
.iconBold::before {
    /* overwrites bootstrap icon font weight */
    font-weight: bold !important;
}
</style>
